<template>
  <div :class="isUserMode ? 'user-mode' : 'admin-mode'">
    <mdb-container class="weather-block">
      <div class="weather-header">
        <h6 class="logo-title" v-if="!isUserMode">
          <img :src="require('@/assets/images/weather/logo.jpg')" alt="Logo" class="logo-image">
          기상청 제공
          <button @click="navigateToMap" class="location-button">위치설정</button>
        </h6>
      </div>
      <h5 class="location-text">
        {{ weather.areaData.city }} {{ weather.areaData.gu }} {{ weather.areaData.city_district }} {{ weather.areaData.dong }}
        {{ weather.areaData.dong ? '' : weather.areaData.quarter }}
        {{ weather.areaData.county }} {{ weather.areaData.town }} {{ weather.areaData.village }}
      </h5>
      <div class="weather-info">
        <mdb-row>
          <mdb-col>
            <h4>오늘 날씨</h4>
              <div class="temperature-container">
                <p class="temperature-text">
                  <img :src="TodaygetSkyConditionImage(weather.today.skyCondition, new Date())" 
                      alt="weather icon" 
                      :class="isUserMode ? 'user-weather-icon' : 'admin-weather-icon'">
                  {{ weather.today.temperature }} °C
                </p>
              </div>
            <p class="weather-description">{{ TodaygetSkyConditionName(weather.today.skyCondition) }}</p>
            <p class="weather-meta">강수확률 {{ weather.today.precipitationProbability }}% ⸰ {{ weather.today.windDirection }} {{ weather.today.windSpeed }}m/s</p>
            <p class="weather-meta">일출 {{ weather.today.sunrise }} ⸰ 일몰 {{ weather.today.sunset }}</p>
          </mdb-col>
          <mdb-col>
            <h4>내일 날씨</h4>
            <mdb-row class="tomorrow-weather-row">
              <mdb-col class="weather-column">
                <div>
                  <h5>오전</h5>
                  <p class="temperature-text2">
                    <img :src="getTomorrowAmIcon()" alt="weather icon" 
                         :class="isUserMode ? 'user-weather-icon' : 'admin-weather-icon'">
                    {{ weather.tomorrow.amTemp }} °C
                  </p>
                  <p class="weather-description">{{ weather.tomorrow.amSkyName }}</p>
                  <p class="weather-meta">강수확률 {{ weather.tomorrow.amPrep }}%</p>
                </div>
              </mdb-col>
              <div class="vertical-divider"></div>
              <mdb-col class="weather-column">
                <div>
                  <h5>오후</h5>
                  <p class="temperature-text2">
                    <img :src="getTomorrowPmIcon()" alt="weather icon" 
                         :class="isUserMode ? 'user-weather-icon' : 'admin-weather-icon'">
                    {{ weather.tomorrow.pmTemp }} °C
                  </p>
                  <p class="weather-description">{{ weather.tomorrow.pmSkyName }}</p>
                  <p class="weather-meta">강수확률 {{ weather.tomorrow.pmPrep }}%</p>
                </div>
              </mdb-col>
            </mdb-row>
            <p class="weather-meta">일출 {{ weather.tomorrow.sunrise }} ⸰ 일몰 {{ weather.tomorrow.sunset }}</p>
          </mdb-col>
        </mdb-row>
      </div>
    </mdb-container>
  </div>
</template>




<script>
import axios from "@/axios";
import { mdbContainer, mdbRow, mdbCol } from "mdbvue";
import Swal from 'sweetalert2';

export default {
name: 'WeatherWidget',
props:{
  isUserMode: {
      type: Boolean,      
      default:false,
    }
},
components: {
mdbContainer,
mdbRow,
mdbCol,
},
data() {
return {
  token: this.$store.state.token.access_token,     
  
  weather: {
    areaData: {},
    NoAreaData: false,
    today: {},
    tomorrow: {},
  },
          
};
},
mounted() {
  this.getLocationfromStore();
  console.log("isUserMode 값 확인:", this.isUserMode);
},

methods: {
  navigateToMap() {
      this.$router.push({ name: 'map', query: { fromHome: 'true' } });
  },

  getLocationfromStore() { // 오타 수정
      this.$store.dispatch('fetchLocation').then(() => {
          const location = this.$store.getters.currentLocation;
          if (location.lat && location.lng) {
              const cachedWeather = this.$store.state.weather;
              if (cachedWeather) {
                  this.weather = cachedWeather;
              }
              this.fetchWeather(location.lat, location.lng);
          } else {
              this.getLocationAndFetchWeather();
          }
      });
  },

  TodaygetSkyConditionName(skyCode) {
      const skyConditionMap = {
          '1': '맑음',
          '2': '구름조금',
          '3': '구름많음',
          '4': '흐림',
      };
      return skyConditionMap[skyCode] || '알 수 없음';
  },
  TodaygetSkyConditionImage(skyCode, time) {
      const isDaytime = this.isDaytime(time);
      const skyImageMapDayTime = {
          '1': require('@/assets/images/weather/w00.jpg'),
          '2': require('@/assets/images/weather/w04.jpg'),
          '3': require('@/assets/images/weather/w05.jpg'),
          '4': require('@/assets/images/weather/w06.jpg'),
      };
      const skyImageMapNightTime = {
          '1': require('@/assets/images/weather/w00-1.jpg'),
          '2': require('@/assets/images/weather/w04-1.jpg'),
          '3': require('@/assets/images/weather/w05-1.jpg'),
          '4': require('@/assets/images/weather/w06-1.jpg'),
      };
      return isDaytime ? skyImageMapDayTime[skyCode] || require('@/assets/images/weather/w00.jpg')
          : skyImageMapNightTime[skyCode] || require('@/assets/images/weather/w00.jpg');
  },

  // 내일 오전/오후 날씨 아이콘을 반환하는 함수
  getSkyConditionImage(skyCode, skyName) {
  const skyImageMapByName = {
      '맑음': require('@/assets/images/weather/w00.jpg'),
      '맑고 한때 비': require('@/assets/images/weather/w10.jpg'),
      '구름많음': require('@/assets/images/weather/w05.jpg'),
      '구름많고 비': require('@/assets/images/weather/w07.jpg'),
      '구름많고 비 곳': require('@/assets/images/weather/w07.jpg'),
      '구름많고 한때 비': require('@/assets/images/weather/w07.jpg'),
      '구름많고 한때 비 곳': require('@/assets/images/weather/w07.jpg'),
      '구름많고 가끔 비': require('@/assets/images/weather/w07.jpg'),
      '구름많고 한때 소나기': require('@/assets/images/weather/w09.jpg'),
      '구름많고 한때 소나기 곳': require('@/assets/images/weather/w09.jpg'),
      '구름많고 한때 비/눈': require('@/assets/images/weather/w11.jpg'),
      '구름많고 가끔 비/눈': require('@/assets/images/weather/w11.jpg'),
      '구름많고 한때 눈 곳': require('@/assets/images/weather/w15.jpg'),
      '흐림': require('@/assets/images/weather/w06.jpg'),
      '흐리고 비': require('@/assets/images/weather/w14.jpg'),
      '흐리고 한때 비': require('@/assets/images/weather/w14.jpg'),
      '흐리고 한때 비 곳': require('@/assets/images/weather/w14.jpg'),
      '흐리고 가끔 비': require('@/assets/images/weather/w14.jpg'),
      '흐리고 소나기': require('@/assets/images/weather/w09.jpg'),
      '흐리고 한때 소나기': require('@/assets/images/weather/w09.jpg'),
      '흐리고 한때 소나기 곳': require('@/assets/images/weather/w09.jpg'),
      '흐리고 가끔 소나기': require('@/assets/images/weather/w09.jpg'),
      '흐리고 한때 눈': require('@/assets/images/weather/w13.jpg'),
      '흐리고 가끔 눈': require('@/assets/images/weather/w13.jpg'),
      '흐리고 비/눈': require('@/assets/images/weather/w11.jpg'),
      '흐리고 한때 비/눈 곳': require('@/assets/images/weather/w11.jpg')
  };

  const skyImageMapByCode = {
      'DB01': require('@/assets/images/weather/w00.jpg'),
      'DB02': require('@/assets/images/weather/w04.jpg'),
      'DB03': require('@/assets/images/weather/w05.jpg'),
      'DB04': require('@/assets/images/weather/w06.jpg')
  };
  // 이중 인용부호 제거
  const cleanedSkyName = (skyName && typeof skyName === 'string') ? skyName.replace(/"/g, '') : '';

  //console.log(`Checking cleaned skyName: '${cleanedSkyName}'`);

  // SkyName으로 매핑된 이미지 찾기
  if (skyImageMapByName[cleanedSkyName.trim()]) {
      //console.log("MapbyName");
      return skyImageMapByName[cleanedSkyName];
  }

  //console.log("SkyName not found in map. Checking skyCode...");
  // SkyCode로 매핑된 이미지 찾기
  return skyImageMapByCode[skyCode] || require('@/assets/images/weather/w00.jpg');
  },

  getTomorrowAmIcon() {
      return this.getSkyConditionImage(this.weather.tomorrow.amSkyCode, this.weather.tomorrow.amSkyName);
  },
  getTomorrowPmIcon() {
      return this.getSkyConditionImage(this.weather.tomorrow.pmSkyCode, this.weather.tomorrow.pmSkyName);
  },

  isDaytime(time) {
  const hours = time.getHours();
  return hours >= 6 && hours < 18;
  },

  getLocationAndFetchWeather() {
  if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
      const { latitude, longitude } = position.coords;
      this.fetchWeather(latitude, longitude);
      }, error => {
      console.error('Error getting location:', error);
      // Default location if user denies access to location
      this.fetchWeather(37.4896527777777, 126.681877777777); // 인천
      });
  } else {
      console.error('Geolocation is not supported by this browser.');
      // Default location if geolocation is not supported
      this.fetchWeather(37.4896527777777, 126.681877777777); // 인천
  }
  },



  fetchWeather(latitude, longitude) {
      const config = {
          method: 'get',
          url: '/weather',
          headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.token}`
          },
          params: { longitude: longitude, latitude: latitude }
      };
      axios(config)
          .then(response => {
              const data = response.data;
              this.weather = {
                  areaData: {
                      city: data.areaData.city,
                      gu: data.areaData.gu,
                      city_district: data.areaData.city_district,
                      dong: data.areaData.dong,
                      quarter: data.areaData.quarter,
                      county: data.areaData.county,
                      town: data.areaData.town,
                      village: data.areaData.village
                  },
                  NoAreaData: data.NoAreaData,
                  today: {
                      temperature: data.todayWeather.temperature,
                      skyCondition: data.todayWeather.skyCondition,
                      waterType: data.todayWeather.waterType,
                      precipitationProbability: data.todayWeather.precipitationProbability,
                      windDirection: data.todayWeather.windDirection,
                      windSpeed: data.todayWeather.windSpeed,
                      sunrise: data.todayWeather.sunrise,
                      sunset: data.todayWeather.sunset,
                  },
                  tomorrow: {
                      amTemp: data.tomorrowWeather.amTemp,
                      pmTemp: data.tomorrowWeather.pmTemp,
                      amPrep: data.tomorrowWeather.amPrep,
                      pmPrep: data.tomorrowWeather.pmPrep,
                      amSkyCode: data.tomorrowWeather.amSkyCode,
                      pmSkyCode: data.tomorrowWeather.pmSkyCode,
                      amSkyName: data.tomorrowWeather.amSkyName.replace(/"/g, ''),
                      pmSkyName: data.tomorrowWeather.pmSkyName.replace(/"/g, ''),
                      amPrepType: data.tomorrowWeather.amPrepType,
                      pmPrepType: data.tomorrowWeather.pmPrepType,
                      sunrise: data.tomorrowWeather.sunrise,
                      sunset: data.tomorrowWeather.sunset,
                  }
              };
              
              this.$store.dispatch('setWeatherData', this.weather);
              
              if (this.weather.NoAreaData) {
                  Swal.fire({
                      icon: 'warning',
                      title: '예보구간코드를 찾지 못했습니다.',
                      text: '해당지역의 예보구간코드를 찾지 못했습니다. 재설정 요망'
                  });
              }
          })
          .catch(error => {
              console.error('날씨 정보를 가져오는 데 실패했습니다:', error);
              
              Swal.fire({
                  icon: 'warning',
                  title: '예보구간코드를 찾지 못했습니다.',
                  text: '위치를 재설정 하세요!'
              });
          });
  },


}
};
</script>

<style scoped >
.logo-title {
font-size: 1.0em;
font-weight: bold;
margin-bottom: 10px;
}

.logo-image {
width: 1em;
height: auto;
margin-right: 4px;
}

.location-button {
margin-left: 20px;
padding: 5px 10px;
background-color: #007bff;
color: white;
border: none;
border-radius: 4px;
cursor: pointer;
}

.location-button:hover {
background-color: #0056b3;
}

.weather-block {
/* border: 2px solid #d3d3d3; */

/* border-radius: 10px; */
border: none;
padding: 5 0px;
background-color: white;
}

.weather-info {
text-align: center;
margin-bottom: 5px;
}

.weather-info h4 {
margin-bottom: 10px;
}

.weather-info p {
margin: 5px 0;
text-align: center;
}
/* ✅ 사용자 모드 (`isUserMode = true`) */
.user-mode .weather-block {
  font-size: 14px;
  max-width: 700px;
  margin: 0 auto;
}

.user-mode .temperature-text {
  font-size: 30px;
  font-weight: bold;
}
.user-mode .temperature-text2 {
  font-size: 12px;
  font-weight: bold;
}


.user-mode .weather-description {
  font-size: 14px;
}

.user-mode .weather-meta {
  font-size: 12px;
}

/* 🌤 사용자 모드 - 날씨 아이콘 크기 조정 */
.user-mode .user-weather-icon {
  width: 40px;
  height: auto;
}


.user-mode .weather-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px; /* 간격 조정 */
}

.user-mode .weather-info .today-weather {
  flex: 1.1; /* 오늘 날씨 영역 */
  text-align: center;
}

.user-mode .weather-info .tomorrow-weather {
  flex: 1.4; /* 내일 날씨 폭을 더 넓힘 */
  text-align: center;
}

.user-mode .weather-column {
  padding: 5px; /* 불필요한 여백 줄이기 */
}

.user-mode .vertical-divider {
  width: 2px; /* 세로 구분선 */
  background-color: #ccc;
  height: 90px;
  margin: 5px 4px; /* 좌우 간격 조정 */
}

/* ✅ 관리자 모드 (`isUserMode = false`) */
.admin-mode .temperature-text {
  font-size: 74px;
  font-weight: bold;
}
.admin-mode .temperature-text2 {
  font-size: 44px;
  font-weight: bold;
}
.admin-mode .weather-description {
  font-size: 20px;
}

.admin-mode .weather-meta {
  font-size: 18px;
}

/* 🌞 관리자 모드 - 날씨 아이콘 크기 조정 */
.admin-mode .weather-icon {
  width: 108px;
  height: auto;
}

/* 📱 모바일 환경 (768px 이하) */
@media screen and (max-width: 768px) {
  .user-mode .weather-block {
    font-size: 12px;
  }

  .user-mode .weather-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 3px;
    gap: 5px; /* 간격 조정 */
  }

  /* 오늘 날씨 폭을 줄이기 */
  .user-mode .today-weather {
    flex: 1; /* 기존 1.1에서 줄임 */
    text-align: center;
    margin-left: -10px; 
  }

  /* 내일 날씨 폭을 더 넓히기 */
  .user-mode .tomorrow-weather {
    flex: 1; /* 기존 1.4에서 증가 */
    text-align: center;
    margin-left: -8px; 
  }
 

  .user-mode .today-weather,
  .user-mode .tomorrow-weather {
    display: flex;
    flex-direction: column;
    justify-content: center; /* 세로 중앙 정렬 */
    align-items: center; /* 가로 중앙 정렬 */
    min-height: 100px; /* 기본 높이 동일하게 설정 */
  }
  /* 불필요한 여백 줄이기 */
  .user-mode .weather-column {
    padding: 5px;
  }

  /* 세로 구분선 */
  .user-mode .vertical-divider {
    width: 2px;
    background-color: #ccc;
    height: 80px;
    margin: 0px auto; 
  }

  .user-mode .temperature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px; /* 아이콘과 온도 사이 간격 */
  padding-top: 20px; /* 높이 맞춤 */
}

  /* 온도 폰트 크기 */
  .user-mode .temperature-text {
    font-size: 20px;
  }
  .user-mode .temperature-text2 {
    font-size: 12px; 
  }

  /* 날씨 아이콘 크기 */
  .user-mode .weather-icon {
    width: 40px;
    
  }
  
  /* 날씨 설명 폰트 크기 */
  .user-mode .weather-description {
    font-size: 12px;
  }

  /* 강수확률, 풍속 같은 추가 정보 */
  .user-mode .weather-meta {
    font-size: 10px;
  }
  
 
  /* 내일 오전/오후 날씨 정렬 */
  .user-mode .tomorrow-weather-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
  }

  .user-mode .tomorrow-weather-row .weather-column {
    flex: 1;
    min-width: 55%;
    text-align: center;
  }

  /* 내일 날씨의 '오전', '오후' 폰트 크기 */
  .user-mode .weather-column h5 {
    font-size: 12px;
  }
}


.vertical-divider {
border-left: thin solid #c8cbcd;
height: 140px;
margin: 10px 10px;
}



</style>